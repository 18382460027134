/* eslint-disable @typescript-eslint/no-explicit-any */
import { Theme, ThemeProvider } from '@sfstudios/shapeshifter';
import React, { createElement } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

const getColor = (props: {
  color: TextColor | 'white' | 'offWhite';
  theme: DefaultTheme;
}) => {
  const { color = 'white', theme } = props;

  switch (color) {
    case 'offWhite':
      return theme.color.ui200;
    case 'white':
      return theme.color.white;
    case 'inherit':
      return 'inherit';

    default:
      return theme.color[color];
  }
};

const typographyTheme = (theme: Theme) => ({
  primaryColor: theme.color.cta,
  ctaColor: theme.color.cta,
  cta600Color: theme.color.cta600,
  cta400Color: theme.color.cta400,
  cta300Color: theme.color.cta300,
  cta200Color: theme.color.cta200,
  error: theme.color.error,
  blackColor: theme.color.ui900,
  ui900Color: theme.color.ui900,
  ui800Color: theme.color.ui800,
  ui700Color: theme.color.ui700,
  ui500Color: theme.color.ui500,
  ui400Color: theme.color.ui400,
  silverColor: theme.color.ui100,
  ui100Color: theme.color.ui100,
  ui200Color: theme.color.ui200,
  ui300Color: theme.color.ui300,
  successColor: theme.color.success,
  whiteColor: theme.color.white,

  disabledOpacity: theme.state.disabledOpacity,

  robotoFont: theme.font.roboto,
  robotoDinFont: theme.font.robotoDin,

  superSize: theme.size.super,
  largeSize: theme.size.large,
  titleSize: theme.size.title,
  textSize: theme.size.text,
  tinyTitleSize: theme.size.tinyTitle,
  infoTextSize: theme.size.infoText,
  tinyInfoSize: theme.size.tinyInfo,

  boldWeight: theme.weight.bold,
  mediumWeight: theme.weight.medium,
  regularWeight: theme.weight.regular,
  lightWeight: theme.weight.light,

  bigLetterSpacing: theme.letterSpacing.big,
  smallLetterSpacing: theme.letterSpacing.small,

  smallLineHeight: theme.lineHeight.small
});

const LinkStyle = css`
  a {
    color: inherit;
    font-size: inherit;
    font-family: ${({ theme }) => theme.font.roboto};
    font-weight: inherit;
    text-decoration: none;
    padding: 0 5px 11px 5px;
    transition: all 150ms linear 0s;

    :hover {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

const SuperBold = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.superSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.boldWeight};
  text-align: ${({ align }: any) => align};
  letter-spacing: ${({ theme }: any) => theme.bigLetterSpacing};

  ${LinkStyle}
`;

const LargeTitleBold = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.largeSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.boldWeight};
  text-align: ${({ align }: any) => align};

  ${LinkStyle}
`;

const LargeTitleRegular = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.largeSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.regularWeight};
  text-align: ${({ align }: any) => align};

  ${LinkStyle}
`;

const TitleBold = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.titleSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.boldWeight};
  text-align: ${({ align }: any) => align};
  letter-spacing: ${({ theme }: any) => theme.bigLetterSpacing};

  ${LinkStyle}
`;

const TitleRegular = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.titleSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.regularWeight};
  text-align: ${({ align }: any) => align};
  letter-spacing: ${({ theme }: any) => theme.bigLetterSpacing};

  ${LinkStyle}
`;

const TextBold = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.textSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.boldWeight};
  text-align: ${({ align }: any) => align};

  ${LinkStyle}
`;

const TextRegular = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.textSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.regularWeight};
  text-align: ${({ align }: any) => align};

  ${LinkStyle}
`;

const TextLight = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.textSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.lightWeight};
  text-align: ${({ align }: any) => align};

  ${LinkStyle}
`;

const TinyTitleBold = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.tinyTitleSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.boldWeight};
  text-align: ${({ align }: any) => align};
  letter-spacing: ${({ theme }: any) => theme.bigLetterSpacing};

  ${LinkStyle}
`;

const TinyTitleMedium = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.tinyTitleSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.mediumWeight};
  text-align: ${({ align }: any) => align};
  letter-spacing: ${({ theme }: any) => theme.bigLetterSpacing};

  ${LinkStyle}
`;

const TinyTitleRegular = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.tinyTitleSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.regularWeight};
  text-align: ${({ align }: any) => align};
  letter-spacing: ${({ theme }: any) => theme.bigLetterSpacing};

  ${LinkStyle}
`;

const InfoTextBold = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.infoTextSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.boldWeight};
  text-align: ${({ align }: any) => align};
  line-height: ${({ theme }) => theme.smallLineHeight};

  ${LinkStyle}
`;

const InfoTextRegular = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.infoTextSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.regularWeight};
  text-align: ${({ align }: any) => align};
  line-height: ${({ theme }) => theme.smallLineHeight};

  ${LinkStyle}
`;

const TinyInfoBold = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.tinyInfoSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.boldWeight};
  text-align: ${({ align }: any) => align};

  ${LinkStyle}
`;

const TinyInfoRegular = styled(({ tag, children, ...props }) =>
  createElement(tag, props, children)
)`
  margin: 0;
  font-size: ${({ theme }) => theme.tinyInfoSize};
  color: ${(props) =>
    props.color !== null ? getColor(props) : props.theme.whiteColor};
  font-family: ${({ theme }) => theme.robotoFont};
  font-weight: ${({ theme }) => theme.regularWeight};
  text-align: ${({ align }: any) => align};
  line-height: 1.1;

  ${LinkStyle}
`;

const defaultHeadlineMapping = {
  superBold: SuperBold,
  largeTitleBold: LargeTitleBold,
  largeTitleRegular: LargeTitleRegular,
  titleBold: TitleBold,
  titleRegular: TitleRegular,
  textBold: TextBold,
  textRegular: TextRegular,
  textLight: TextLight,
  tinyTitleBold: TinyTitleBold,
  tinyTitleMedium: TinyTitleMedium,
  tinyTitleRegular: TinyTitleRegular,
  infoTextBold: InfoTextBold,
  infoTextRegular: InfoTextRegular,
  tinyInfoBold: TinyInfoBold,
  tinyInfoRegular: TinyInfoRegular
};

export type TextColor =
  | 'ui200'
  | 'ui300'
  | 'ui400'
  | 'ui900'
  | 'cta'
  | 'white'
  | 'inherit';

export interface TypographyProps
  extends Partial<React.HTMLAttributes<HTMLElement>> {
  tag?: string;
  children: React.ReactNode;
  variant?:
    | 'superBold'
    | 'largeTitleBold'
    | 'largeTitleRegular'
    | 'titleBold'
    | 'titleRegular'
    | 'textBold'
    | 'textRegular'
    | 'textLight'
    | 'tinyTitleBold'
    | 'tinyTitleMedium'
    | 'tinyTitleRegular'
    | 'infoTextBold'
    | 'infoTextRegular'
    | 'tinyInfoBold'
    | 'tinyInfoRegular'
    | undefined;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  color?: 'offWhite' | 'white' | TextColor;
  lineThrough?: boolean;
}

const Typography: React.FC<TypographyProps> = ({
  tag = 'p',
  variant = 'textRegular',
  children,
  color,
  lineThrough: _lineThrough,
  ...props
}) => {
  const Component = defaultHeadlineMapping[variant] || 'p';

  return (
    <ThemeProvider theme={typographyTheme}>
      <Component tag={tag} color={color} {...props}>
        {children}
      </Component>
    </ThemeProvider>
  );
};

export default Typography;
